import React from "react";
import Header from "../components/header/Header";
import Content from "../../../../components/main/content/content";
import Sidebar from "../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import { graphql } from "gatsby";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  let temp = data?.cms?.recent_story;
  let featured_post = temp.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }
  return (
    <div className="layout">
      <Header category={data?.cms?.categories} allPosts={allPosts} engine={options} />
      <div className="container is-max-widescreen">
        <div className="columns m-0">
          <Content story={data?.cms?.page} domain={data?.cms?.domain} />
          <Sidebar recent_story={featured_post} />
        </div>
      </div>
      <Footer
        pages={data?.cms?.pages}
        domain={data?.cms?.domain}
        email={"editor@thenerdseries.com"}
      />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query pages($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "1") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "1") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "1") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      page: CMS_Page(id: $slug) {
        content
        slug
        title
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
